import * as React from "react"
import Layout from "../components/utils/layout"
import Loading from "../components/utils/loading"

import "../components/css/utils/loadingPage.scss"

const LoadingPage = () => (
  <Layout>
    <Loading />
  </Layout>
)

export default LoadingPage
