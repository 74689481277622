import React from "react"
import Loader from "react-loader-spinner";

import "../css/utils/loading.scss"

const Loading = () => {
  return (
    <>
      <div id="loading-div">
        <Loader type="Oval" color="#2ecc71" height={30} width={30}/>
        <h6>Fetching data...</h6>
      </div>
    </>
  )
}

export default Loading
